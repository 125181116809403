<template>
  <div>
    <KTCard>
      <template v-slot:title>
        Preview
      </template>
      <template v-slot:toolbar>
        <div>
          <b-button
              :disabled="loading > 0"
              @click="downloadPdf($route.query.id)"
              class="mt-3 mr-2"
              size="sm"
              type="submit"
              variant="primary"
          >
            <i class="fa fa-spin fa-spinner"
               v-show="loading > 0"></i>
            <i class="fa fa-file-pdf"></i>
            Download
          </b-button>
        </div>
      </template>


      <template v-slot:body>
        <b-container class="w-75" fluid>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              <div v-if="detail.leads.property">
                <div v-if="detail.leads.property.company">
                  <div v-if="detail.leads.property.company.logo">
                    <img :src="detail.leads.property.company.logo.download_url" height="100" />
                  </div>
                  <div v-else>
                    <h5>{{detail.leads.property.company.company_name}}</h5>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              {{(detail.leads.property || {}).name}}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              {{(detail.leads.property || {}).address}}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              <div v-if="detail.leads.property">
                {{(detail.leads.property || {}).name}}
                {{(detail.leads.property || {}).state_name}}
                {{(detail.leads.property || {}).country_name}}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              <div v-if="detail.leads.email">
                {{(detail.leads || {}).email}}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              {{(detail.leads || {}).alternate_mobile_number}}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              {{(detail.leads || {}).alternate_mobile_number}}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center" sm="12" v-if="detail.leads">
              <div v-if="detail.leads.property">
                <div v-if="detail.leads.property.company">
                  {{(detail.leads.property.company || {}).website}}
                </div>
              </div>
            </b-col>
          </b-row>
          <div v-if="detail.leads">
            <b-row class="mt-5">
              <b-col class="text-center mb-2" sm="12">
                <h2 class="underline font-weight-bold font-size-24">BANQUET FUNCTION DETAILS</h2>
              </b-col>

              <b-col class="m-auto" sm="12">
                <div class="table-responsive w-100">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="text-decoration-underline">Guest Name</th>
                        <th class="text-decoration-underline">Booking Date</th>
                        <th class="text-decoration-underline">Contact No.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ (detail.leads || {}).first_name+ ' '+
                          (detail.leads || {}).last_name}}
                        </td>
                        <td>
                          {{detail.events.event_from_date ?
                          $global.dateConvert(detail.events.event_from_date) :""}}
                        </td>
                        <td> {{detail.leads.mobile_number}}</td>
                      </tr>
                      <tr v-if="(detail.leads || {}).address">
                        <th class="text-decoration-underline"> Residing Address :-</th>
                        <td colspan="2">
                          {{(detail.leads || {}).address}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mt-10" v-if="detail.quotationEvents">
            <div :key="index"
                 class="mb-20"
                 v-for="(eventFunction,index) in  _.sortBy(detail.quotationEvents,'from_date')">
              <h1 class="font-weight-bold underline font-size-24 text-center mb-2">
                <span>Day {{index+1}}: </span>
                {{eventFunction.from_date ? $global.dateFormat(eventFunction.from_date) : ""}}
              </h1>
              <div class="table-responsive">
                <table class="table table-bordered w-100">
                  <thead>
                    <tr>
                      <th class="text-capitalize" colspan="2">Function :-
                        {{eventFunction.event_type}}
                      </th>
                      <th class="text-capitalize">Session :- {{eventFunction.slot}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span class="font-weight-bold underline">Time:-</span>
                        <span>{{$global.timeFormatA(eventFunction.from_time)}} to {{$global.timeFormatA(eventFunction.to_time)}}</span>
                      </td>
                      <td>
                        <span class="font-weight-bold underline">Person:-</span>
                        {{eventFunction.fnb ? eventFunction.fnb.no_of_pax +' Pax' : '-'}}
                        <br />
                      </td>
                      <td>
                        <span class="font-weight-bold underline">Venue:-</span>
                        {{eventFunction.halls.map(i => i.name).join(", ") }}
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive mt-10" v-if="eventFunction.fnb">
                <h5 class="text-center font-weight-bold font-size-lg underline mb-2">F&B</h5>
                <table class="table table-bordered m-auto">
                  <tbody>
                    <tr :key="i"
                        class="mt-5" v-for="(l,i) in eventFunction.fnb.parent_food_label">
                      <th>{{l.category}} <br /> <span v-if="l.description"> ({{l.description}}) </span>
                      </th>
                      <td>
                        <ol class="w-50 m-auto">
                          <li :key="index"
                              v-for="(c,index) in  _.filter(eventFunction.fnb.children_food_label,(i) => i.parent_category_id == l.id)">
                            {{c.category}}
                            <p v-if="c.description">({{c.description}})</p>
                          </li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive mt-10" v-if="eventFunction.fnbExtras.length">
                <h5 class="text-center font-weight-bold font-size-lg underline mb-2">F&B Extras</h5>
                <table class="table table-bordered m-auto">
                  <thead>
                    <tr>
                      <th class="underline">Title</th>
                      <th class="underline">No Of Pax</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(fnb_extras,index) in eventFunction.fnbExtras">
                      <th>{{(fnb_extras.fnbExtras || {}).title}}</th>
                      <td>{{fnb_extras.fnbExtras.no_of_pax ? fnb_extras.fnbExtras.no_of_pax : '-'}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive mt-10" v-if="eventFunction.decors.length">
                <h5 class="text-center font-weight-bold font-size-lg underline mb-2">Decoration</h5>
                <table class="table table-bordered m-auto w-50">
                  <thead>
                    <tr>
                      <th class="underline">Title</th>
                      <th class="underline">Rate/deliverables</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(d,index) in eventFunction.decors">
                      <th>{{(d.decor || {}).name}}</th>
                      <td>
                        <p>{{d.decor.rate ? $global.numberToLocationString(d.decor.rate ) :
                          '-'}}</p>
                        <p>{{d.deliverables}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <div class="table-responsive mt-10" v-if="eventFunction.otherCharges.length">
                <h5 class="text-center font-weight-bold font-size-lg underline mb-2">Other Charges</h5>
                <table class="table table-bordered m-auto w-50">
                  <thead>
                    <tr>
                      <th class="underline">Title</th>
                      <th class="underline">Rate/No of pax</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(oc,index) in eventFunction.otherCharges">
                      <th>{{(oc.otherCharge || {}).particular}}</th>
                      <td>
                        <p>{{$global.numberToLocationString(oc.rate)}}</p>
                        <p v-if="(oc.otherCharge || {}).no_of_pax"> {{oc.no_of_pax}}
                          {{oc.otherCharge.type}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="eventFunction.guestNote">
                <b-row class="mt-5">
                  <b-col class="text-center" sm="12">
                    <b-card>
                      <b-card-header>
                        <h5 class="font-weight-bold font-size-lg text-decoration-underline">
                          Guest Note</h5>
                      </b-card-header>
                      <b-card-body>
                        <div v-html="eventFunction.guestNote.note"></div>
                      </b-card-body>
                    </b-card>
                  </b-col>
                </b-row>
              </div>

              <div class="table-responsive mt-10">
                <h3 class="underline font-size-lg mb-2 font-weight-bold text-center">Date Wise Summary
                  of Charges</h3>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Item</th>
                      <th>Rate</th>
                      <th>No. Of Guests</th>
                      <th>Sub Total</th>
                      <th>GST@(CGST + SGST)%</th>
                      <th>GST Amount</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{eventFunction.from_date ?
                        $global.dateConvert(eventFunction.from_date)
                        : ""}}
                        <br />
                        {{$global.timeFormatA(eventFunction.from_time)}} -
                        {{$global.timeFormatA(eventFunction.to_time)}}
                      </td>
                      <td>F&B</td>
                      <td id="amount">
                        {{totalAmountS(eventFunction.fnb).rate}}
                      </td>
                      <td>
                        {{totalAmountS(eventFunction.fnb).guest}}
                      </td>
                      <td id="amount">
                        {{totalAmountS(eventFunction.fnb).amount}}
                      </td>
                      <td>{{ totalAmountS(eventFunction.fnb).gst}}</td>
                      <td id="amount">
                        {{totalAmountS(eventFunction.fnb).gstAmount}}
                      </td>
                      <td id="amount">
                        {{totalAmountS(eventFunction.fnb).total}}
                      </td>
                    </tr>
                    <tr v-if="detail.quotationEvents.fnbExtras">
                      <td></td>
                      <td>Fnb Extra</td>
                      <td id="amount">
                        {{totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).rate}}
                      </td>
                      <td>
                        {{totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).guest}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).amount}}
                      </td>
                      <td>
                        {{totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).gst}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).gstAmount}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).total}}
                      </td>
                    </tr>
                    <tr v-if="eventFunction.decors">
                      <td></td>
                      <td>Decors</td>
                      <td id="amount">
                        {{totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).rate}}
                      </td>
                      <td>
                        {{totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).guest}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).amount}}
                      </td>
                      <td>
                        {{totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).gst}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).gstAmount}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).total}}
                      </td>
                    </tr>
                    <tr v-if="eventFunction.otherCharges">
                      <td></td>
                      <td>Other Charges</td>
                      <td id="amount">
                        {{totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).rate}}
                      </td>
                      <td>
                        {{totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).guest}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).amount}}
                      </td>
                      <td>
                        {{totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).gst}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).gstAmount}}
                      </td>
                      <td id="amount">
                        {{totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).total}}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {{Number(totalAmountS(eventFunction.fnb).guest +
                        (totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).guest)
                        +(totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).guest)
                        +(totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).guest)).toLocaleString()}}
                      </td>
                      <td id="amount">
                        {{Number(totalAmountS(eventFunction.fnb).amount +
                        (totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).amount)
                        +(totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).amount)
                        +(totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).amount)).toLocaleString()}}
                      </td>
                      <td></td>
                      <td></td>
                      <td id="amount">
                        <strong>
                          {{Number(totalAmountS(eventFunction.fnb).total +
                          (totalAmountS({'fnbExtras':detail.quotationEvents.fnbExtras,'fnbExtrasDiscount':detail.quotationEvents.fnbExtrasDiscount}).total)
                          +(totalAmountS({'decors':eventFunction.decors,'decorsDiscount':eventFunction.decorsDiscount}).total)
                          +(totalAmountS({'otherCharges':eventFunction.otherCharges,'otherChargesDiscount':eventFunction.otherChargesDiscount}).total)).toLocaleString()}}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />
            </div>
          </div>


          <div class="text-center mt-10 mb-10" v-if="detail.quotationBookingFinancial">
            <h5>Payment Details</h5>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>SI No.</th>
                    <th>Mode Of Payment</th>
                    <th>Date Of Payment</th>
                    <th>Paid Amount</th>
                    <th>Due Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(data,index) in detail.quotationBookingFinancial">
                    <td>{{index +1}}</td>
                    <td>{{data.mode_of_payment}}</td>
                    <td>{{data.date_of_payment ? $global.dateConvert(data.date_of_payment) :
                      ""}}
                    </td>
                    <td>{{data.paid_amount}}</td>
                    <td>{{data.due_amount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr />

          <div class="mb-5 text-center">
            ----- END -----
          </div>
        </b-container>
      </template>
    </KTCard>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { request } from "@/core/services/Request";
  import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";
  import KTCard from "@/view/content/Card.vue";


  export default {
    mixins: [totalAmountMixin],
    components: {
      KTCard,
    },
    data() {
      return {
        dropdowns: {
          foodMenus: [],
        },
        detail: [],
        loading: false,
      };
    },
    mounted() {
      if ( this.$route.params && this.$route.params.id ) {
        this.handleEditOperation(this.$route.params.id);
      }
    },
    methods: {
      async handleEditOperation(id) {

        try {
          const response = await request({
            method: 'get',
            url: `/quotations/detail/${ id }`,
          });
          const { data } = response.data;
          this.detail = data;
        } catch (e) {
          this.$global.itemEditFails();
        }
      },
      async downloadPdf(id) {
        this.loading = true;
        try {
          const response = await request({
            method: 'get',
            url: `bookings/download/pdf/${ id }`,
          });

          const { data } = response.data;

          if ( data ) {
            window.location.href = data.download_url;
          }

        } catch (e) {
          this.loading = false;
        }
      },
    },
    computed: {
      ...mapState([
        'global',
      ]),
    },
  };
</script>

<style scoped>
  p {
    margin: 0
  }

  .underline {
    text-decoration: underline;
  }

  .fa {
    cursor: pointer;
  }

  table tr, table td, table th {
    text-align: center;
    text-transform: capitalize;
  }

  .table-bordered th, .table-bordered td, .card {
    border-color: #0000004d;
  }

  .card-header {
    border-bottom: 1px solid #0000004d;
  }

  .font-size-24 {
    font-size: 24px;
  }
</style>
